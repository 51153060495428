import moment from "moment";
import { apiServiceInstance } from "@/core/helpers/utils";
import {
  ApiResponse,
  IBangKeChiTietXuatResponseBase,
  IChiTietXuatTheoDoiTuongResponseBase,
  IHoaDonNhapKhoResponseBase,
  ITheoDoiThucHienHopDongResponseBase,
  ITongHopVatTuResponseBase, ITongHopVatTuTheoDoiTuongResponseBase,
  IVatTuHangTonKhoResponseBase
} from '../interfaces/ApiResponses';

export class BaoCaoService {
  static getSoCanDoiVatTu({
    from, to, khoId
  }: {
    from: number;
    to: number;
    khoId: number;
  }): Promise<{
    data: ApiResponse<IVatTuHangTonKhoResponseBase[]>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bao-cao/so-can-doi-vat-tu?query[id-kho]=${khoId}&query[from-month]=${from}&query[to-month]=${to}`,
    );
  }

  static getBangKeChiTietNhap({
    from,
    to,
    idKho,
    mact,
    matk,
  }: {
    from: number;
    to: number;
    idKho: number;
    mact: string,
    matk: string;
  }): Promise<{
    data: ApiResponse<IHoaDonNhapKhoResponseBase[]>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bao-cao/bang-ke-chi-tiet-nhap?query[id-kho]=${idKho}&query[from-month]=${from}&query[to-month]=${to}&query[ma-tk]=${matk}&query[ma-ct]=${mact}`,
    );
  }

  static getBangKeChiTietXuat({
    capSuaChuaId,
    khoId,
    from,
    to,
    toaXeId,
  }: {
    capSuaChuaId: number;
    khoId: number;
    toaXeId: number;
    from: number;
    to: number;
  }): Promise<{
    data: ApiResponse<IBangKeChiTietXuatResponseBase[]>
  }> {
    // TODO: revert later
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bao-cao/bang-ke-chi-tiet-xuat?query[id-kho]=${khoId}&query[from-month]=${from}&query[to-month]=${to}&query[id-cap-sc]=${capSuaChuaId}&query[id-toa-xe]=${toaXeId}`,
    );

    // TODO: comment out later
    // return apiServiceInstance().vueInstance.axios.get(
    //   `/api/v1/bao-cao/bang-ke-chi-tiet-xuat?query[id-kho]=${1}&query[from-month]=${7}&query[to-month]=${12}&query[id-cap-sc]=${6}&query[id-toa-xe]=${2023}`,
    // );
  }

  static async getTongHopVatTu({
    khoId,
    isTongHopNhap,
    from,
    to,
  }: {
    khoId: number;
    isTongHopNhap: boolean;
    from: number;
    to: number;
  }): Promise<ITongHopVatTuResponseBase[]> {
    const type = isTongHopNhap ? 'tong-hop-nhap' : 'tong-hop-xuat';

    const { data } = await apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bao-cao/${type}?query[id-kho]=${khoId}&query[from-month]=${from}&query[to-month]=${to}`,
    );

    return data.data.map(item => ({
      don_vi_tinh: item.don_vi_tinh,
      id: item.id,
      ma_vat_tu: item.ma_vat_tu,
      don_gia: +item.nhap_trong_ky_dg ?? +item.xuat_trong_ky_dg ?? 0,
      so_luong: +item.nhap_trong_ky_slg ?? +item.xuat_trong_ky_slg ?? 0,
      thanh_tien: +item.nhap_trong_ky_tt ?? +item.xuat_trong_ky_tt ?? 0,
      ten_vat_tu: item.ten_vat_tu,
    }));
  }

  static getBangKeChiTietXuatTheoDoiTuong({
    capSuaChuaId,
    khoId,
    from,
    to,
    toaXeId,
  }: {
    capSuaChuaId: number;
    khoId: number;
    from: Date;
    to: Date;
    toaXeId: number;
  }): Promise<{
    data: ApiResponse<IChiTietXuatTheoDoiTuongResponseBase[]>
  }> {
    const fromDate = moment(from).format('YYYY-MM-DD');

    const toDate = moment(to).format('YYYY-MM-DD');

    // TODO: revert later
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bao-cao/bang-ke-chi-tiet-xuat-theo-doi-tuong?query[id-kho]=${khoId}&query[start-date]=${fromDate}&query[end-date]=${toDate}&query[id-cap-sc]=${capSuaChuaId}&query[id-toa-xe]=${toaXeId}`,
    );

    // TODO: must be commented out
    // return apiServiceInstance().vueInstance.axios.get(`/api/v1/bao-cao/bang-ke-chi-tiet-xuat-theo-doi-tuong?query[id-kho]=2&query[start-date]=2023-07-01&query[end-date]=2023-07-31&query[id-cap-sc]=5&query[id-toa-xe]=926`);
  }

  static getTheoDoiThucHienHopDong({
    idHopDong,
    idKho,
    month,
  }: {
    idHopDong: number;
    idKho: number;
    month: number;
  }): Promise<{
    data: ApiResponse<ITheoDoiThucHienHopDongResponseBase[]>
  }> {
    // TODO: revert later
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bao-cao/theo-doi-thuc-hien-hop-dong?query[id-hop-dong]=${idHopDong}&query[id-kho]=${idKho}&query[month]=${month}`,
    );

    // TODO: comment out later
    // return apiServiceInstance().vueInstance.axios.get(
    //   `/api/v1/bao-cao/theo-doi-thuc-hien-hop-dong?query[id-hop-dong]=${149}&query[id-kho]=${3}&query[month]=${7}`,
    // );
  }

  static getTongHopVatTuTheoDoiTuong({
    kho_id,
    month,
  }: {
    kho_id: number;
    month: number;
  }): Promise<{
    data: ApiResponse<ITongHopVatTuTheoDoiTuongResponseBase[]>
  }> {
    return apiServiceInstance().vueInstance.axios.get(
      `/api/v1/bao-cao/tong-hop-nhap-vat-tu-theo-doi-tuong?idKho=${kho_id}&month=${month}`,
    );
  }
}
